import { useDispatch, useSelector } from "react-redux";
import { twdClass } from "../../../constants/tailwind.constants";
import { Button} from "../../ui/com_ui";
import { HelpMenu, LogoMenu } from "../../common/ui";
import {
  RESET_POPUP,
  UPDATE_PROJECT_INFO,
  UNDO_BLOCKS_HISTORY,
  REDO_BLOCKS_HISTORY,
  SELECT_BLOCK_ONCLICK,
  RIGHT_PANEL_TOGGEL,
  UPDATE_BLOCK_DATA,
  CREATE_NEW_PAGE,
  SELECT_PAGE_ID,
} from "../../../constants/action.constants";
import { useState, useEffect } from "react";
import { INPUT_TEXT, BTN_CLS } from "../../../constants/var.constants";
import { saveProject, getProjectInfo } from "../../../api/services";
import { toast } from "react-toastify";

const HistoryC = (props) => {
  const dispatch = useDispatch();
  const {
    pageInfo,
    history:{page},
    projectData: { project_id, pages },
  } = useSelector((state) => state.studioDetails);
  
  return (
    <div className={twdClass("flex items-center gap-2 bg-gray-100 rounded-full pl-2 pr-2 py-2 ")}>          
        <Button onClick={() => {               
            //let items=[]
            //items=page.items.slice(0, page.currentIndex-1)     
            //console.log("items", items)        
            dispatch({ type: UNDO_BLOCKS_HISTORY, data:false});
            dispatch({type: SELECT_BLOCK_ONCLICK, data: null}); 
            dispatch({ type: RIGHT_PANEL_TOGGEL, data: false });       
          }} 
          onoff={'on'} 
          disabled={page.currentIndex==0 ? true : false}
          type={'icon'}      
          color={'white'}
          size={'sm'}         
          iconImg={'undo.svg'}         
          className={''}        
        ></Button>    
        <Button onClick={() => {
            dispatch({ type: REDO_BLOCKS_HISTORY, data:false});
            dispatch({type: SELECT_BLOCK_ONCLICK, data: null}); 
            dispatch({ type: RIGHT_PANEL_TOGGEL, data: false });       
          }} 
          onoff={'on'}  
          disabled={page.currentIndex >= page.items.length-1 ? true : false} 
          type={'icon'}      
          color={'white'}
          size={'sm'}         
          iconImg={'redo.svg'}          
          className={''}          
        ></Button>        
      </div>
  );
};

export default HistoryC;
