import DetailsElemLabel from "./elementprops/DetailsElemLabel";
import DetailsElementsProps from "./elementprops/DetailsElementsProps";
import ElementMenu from "./ElementMenu";

const SettingsPropsDetails = ({
  sectionData,
  sectionElem,
  blocks,
  styleElementId,
  listIndex,
}) => {
  let menu = <ElementMenu 
    sectionData={sectionData}
    sectionElem={sectionElem}
    styleElementId={styleElementId}
    blocks={blocks}
    listIndex={listIndex}
  />
  return (
    <>
      <DetailsElemLabel
        sectionElem={sectionElem}
        styleElementId={styleElementId}
        menu={menu}
      />
      <DetailsElementsProps
        sectionData={sectionData}
        sectionElem={sectionElem}
        styleElementId={styleElementId}
        blocks={blocks}
        listIndex={listIndex}
      />
    </>
  );
};

export default SettingsPropsDetails;
