export const RIGHT_PANEL_TOGGEL = "RIGHT_PANEL_TOGGEL";
export const RESET_RIGHT_PANEL_LEVELS = "RESET_RIGHT_PANEL_LEVELS";
export const ADD_RIGHT_PANEL_LEVEL = "ADD_RIGHT_PANEL_LEVEL";
export const BACK_RIGHT_PANEL_LEVEL = "BACK_RIGHT_PANEL_LEVEL";
export const UPDATE_RIGHT_PANEL_LEVELS = "UPDATE_RIGHT_PANEL_LEVELS";
export const ADD_BLOCK_TO_PAGE = "ADD_BLOCK_TO_PAGE";
export const UPDATE_BLOCK_DATA = "UPDATE_BLOCK_DATA";
export const UPDATE_STYLE_ID = "UPDATE_STYLE_ID";
export const UPDATE_STYLE_PART = "UPDATE_STYLE_PART";
export const UPDATE_ADVANCED_ID = "UPDATE_ADVANCED_ID";
export const SELECT_BLOCK_ONCLICK = "SELECT_BLOCK_ONCLICK";
export const UPDATE_PROJECT_INFO = "UPDATE_PROJECT_INFO";
export const RESET_PROJECT_DETAILS = "RESET_PROJECT_DETAILS";
export const UPDATE_PROJECT_PAGE = "UPDATE_PROJECT_PAGE";
export const TOGGEL_POPUP = "TOGGEL_POPUP";
export const RESET_POPUP = "RESET_POPUP";
export const TOGGEL_CONFIRM_POPUP = "TOGGEL_CONFIRM_POPUP";
export const RESET_CONFRIM_POPUP = "RESET_CONFRIM_POPUP";
export const UPDATE_LIST_INDEX = "UPDATE_LIST_INDEX";
export const UPDATE_PAGE_SETTINGS = "UPDATE_PAGE_SETTINGS";
export const CREATE_NEW_PAGE = "CREATE_NEW_PAGE";
export const UPDATED_CONNECTIONS = "UPDATED_CONNECTIONS";
export const SELECT_PAGE_ID = "SELECT_PAGE_ID";
export const UPADTE_PAGES_LIST = "UPADTE_PAGES_LIST";
export const UPDATE_SITE_SETTINGS = "UPDATE_SITE_SETTINGS";
export const UPDATE_PREVIEW_TYPE = "UPDATE_PREVIEW_TYPE";
export const RESET_PREVIEW_TYPE = "RESET_PREVIEW_TYPE";
export const RESET_AUTOSAVE = "RESET_AUTOSAVE";

export const UPDATE_VISUAL_STEPS = "UPDATE_VISUAL_STEPS";
export const UPDATE_APP_PREF = "UPDATE_APP_PREF";
export const UPDATE_GENERATED = "UPDATE_GENERATED"
export const RESET_GENERATED = "RESET_GENERATED"
export const RESET_HISTORY = "RESET_HISTORY"
export const UNDO_BLOCKS_HISTORY = "UNDO_BLOCKS_HISTORY"
export const REDO_BLOCKS_HISTORY = "REDO_BLOCKS_HISTORY"

export const COPY_ELEMENT_STYLES = "COPY_ELEMENT_STYLES"



