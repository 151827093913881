import { Scrollbars } from 'react-custom-scrollbars';
import { twdClass } from "../../../constants/tailwind.constants";
import { useDispatch, useSelector } from "react-redux";
import FaIcons from "../../common/FaIcons";
import { Button} from "../../ui/com_ui";
import { RIGHT_PANEL_TOGGEL, UPDATE_RIGHT_PANEL_LEVELS } from "../../../constants/action.constants";
// import { GenetateForm } from "../../form/GenerateForm";
import SettingsPanel from "../../form/SettingsPanel";
import styled from "styled-components";
import { useState, useEffect, useRef } from "react";

const RS_CLS_1 = twdClass(
  "flex items-center text-2xl px-2 pt-4 pb-2 border-b border-gray-100 "
);
const RS_CLS_2 = twdClass("ml-4 text-2xl font-semibold text-black opacity-0");

const RightSidePanel = ({ showPanel }) => {
  const dispatch = useDispatch();
  const [scrollTop, setScrollTop] = useState(0);
  const scrollbarsRef = useRef(null);

  const {
    rightPanelLevels
  } = useSelector((state) => state.studioDetails);
  
  const handleUpdate = (o) => {
    //const {scrollTop} = o
    //console.log("scrollTop", scrollTop )
    //setScrollTop(scrollTop)
    //dispatch({ type: UPDATE_RIGHT_PANEL_LEVELS, data: {...rightPanelLevels, currentScrollTop:scrollTop} });
  }
  const handleScroll = (e) => {
    const {scrollTop} = e.target
    //console.log("scrollTop", scrollTop )
    //setScrollTop(scrollTop)
    dispatch({ type: UPDATE_RIGHT_PANEL_LEVELS, data: {...rightPanelLevels, currentScrollTop:scrollTop} });
  }
  
  useEffect(()=>{
    
    if(rightPanelLevels.back){
      const {scrollTop}=rightPanelLevels.items[rightPanelLevels.currentIndex]
      const items=[...rightPanelLevels.items]
      items.pop()
      //console.log('items', rightPanelLevels.items, items)
      setTimeout(()=>{
        scrollbarsRef.current.scrollTop(scrollTop);
        //console.log("timeout scroll")
      }, 200)
      
      dispatch({ type: UPDATE_RIGHT_PANEL_LEVELS, data: {back:false, items, currentIndex:rightPanelLevels.currentIndex-1} });
      //console.log("useEffect")
    }
  })
  return (
    <div className={`${showPanel ? "" : twdClass("hidden")}`}>
      <div className={RS_CLS_1}>        
        <Button
          type={'icon'}
          color={'black'}
          iconImg={'chevron-double-left.svg'}
          onClick={() => {
            dispatch({ type: RIGHT_PANEL_TOGGEL, data: false });
          }}
        >
          <FaIcons icon="angledoubleright" />
        </Button>
        <div className={RS_CLS_2}>Settings</div>
      </div>     
      <SDiv>
        <Scrollbars ref={scrollbarsRef} style={{ width: '100%', height: '100%' }} autoHide  onScroll={handleScroll} onUpdate={handleUpdate}>
          <SettingsPanel/>
        </Scrollbars>
      </SDiv>
    </div>
  );
};

export default RightSidePanel;

const SDiv = styled.div`
  height: calc(100vh - 150px);
  overflow: auto;
`;
