import { twdClass } from "../../../constants/tailwind.constants";
import { useSelector, useDispatch } from "react-redux";
import { BTN_CLS } from "../../../constants/var.constants";
import { UPDATE_SITE_SETTINGS } from "../../../constants/action.constants";
import { useEffect, useState } from "react";
import { getSiteSettings, saveSiteSettings } from "../../../api/services";
import { toast } from "react-toastify";
import MetadataSettings from "./MetadataSettings";
import classNames from "classnames";
import ExternalJS from "./ExternalJS";
import ExternalCSS from "./ExternalCSS";
import HeadTags from "./HeadTags";
import Sitemap from "./Sitemap";
import Robots from "./Robots";
import Ads from "./Ads";
import Manifest from "./Manifest";
import ServiceWorker from "./ServiceWorker";
import Advanced from "./Advanced";

import GoogleFonts from "./GoogleFonts";
import Fonts from "./Fonts";
import {Button} from "../../ui/com_ui";

const SSP1 = `text-sm px-4 py-2 rounded-tl-2xl rounded-bl-2xl hover:bg-white cursor-pointer mb-1`;
const TABS_LIST = [
  { id: "seo", label: "SEO" },
  { id: "headtags", label: "Head Tags" },
  { id: "extjs", label: "External Javascipt" },
  { id: "extcss", label: "External CSS" },
  //{ id: "googlefonts", label: "Google Fonts" },
  { id: "fonts", label: "Fonts" },
  { id: "sitemap", label: "Sitemap" },
  { id: "robots", label: "Robots" },
  { id: "ads", label: "Ads.txt" },
  { id: "manifest", label: "Manifest" },
  { id: "serviceworker", label: "Service Worker" },
  { id: "advanced", label: "Advanced" },
];

const SiteSettingsPanel = () => {
  const dispatch = useDispatch();
  const {
    sitesettings,
    projectData: { project_id, domain},
  } = useSelector((state) => state.studioDetails);
  const da = useSelector((state) => state.studioDetails);
  //console.log("p",domain)
  useEffect(() => {
    getSiteSettings(project_id).then((resp) => {
      if (resp.success) {
        dispatch({ type: UPDATE_SITE_SETTINGS, data: resp.data });
      }
    });
  }, []);

  const saveSettings = () => {
    saveSiteSettings(project_id, sitesettings).then((resp) => {
      toast.success("Site settings saved!");
    });
  };

  const updateSettings = (data) => {
    dispatch({ type: UPDATE_SITE_SETTINGS, data });
  };

  const [activeTab, setActiveTab] = useState("seo");

  let sitemapUrl=`https://${domain}/sitemap`
  if(sitesettings?.sitemapType =='xml'){
    sitemapUrl+='.xml'
  }else if(sitesettings?.sitemapType =='txt'){
    sitemapUrl+='.txt'
  }

  return (
    <div className={`site-settings-cls ${twdClass("py-2 ")}`}>
      <div className={twdClass("w-full flex")}>
        <div className={twdClass("w-52 font-semibold")}>
          {TABS_LIST.map((item) => {
            const cls = classNames(SSP1, {
              "bg-white": item.id === activeTab,
            });
            return (
              <div
                className={twdClass(cls)}
                onClick={() => setActiveTab(item.id)}
              >
                {item.label}
              </div>
            );
          })}
        </div>
        <div className={twdClass("w-full bg-white px-8 py-4 -mx-4 rounded-2xl -mb-3")}>
          {activeTab === "seo" && (
            <MetadataSettings
              sitesettings={sitesettings}
              updateSettings={updateSettings}
            />
          )}
          {activeTab === "headtags" && (
            <HeadTags
              sitesettings={sitesettings}
              updateSettings={updateSettings}
            />
          )}
          {activeTab === "extjs" && (
            <ExternalJS
              sitesettings={sitesettings}
              updateSettings={updateSettings}
            />
          )}
          {activeTab === "extcss" && (
            <ExternalCSS
              sitesettings={sitesettings}
              updateSettings={updateSettings}
            />
          )}
          {activeTab === "googlefonts" && (
            <GoogleFonts
              sitesettings={sitesettings}
              updateSettings={updateSettings}
            />
          )}
          {activeTab === "fonts" && (
            <Fonts
              sitesettings={sitesettings}
              updateSettings={updateSettings}
            />
          )}
          {activeTab === "sitemap" && (
            <Sitemap
              sitesettings={sitesettings}
              updateSettings={updateSettings}
            />
          )}
          {activeTab === "robots" && (
            <Robots
              sitesettings={sitesettings}
              updateSettings={updateSettings}
            />
          )}
          {activeTab === "ads" && (
            <Ads
              sitesettings={sitesettings}
              updateSettings={updateSettings}
            />
          )}
          {activeTab === "manifest" && (
            <Manifest
              sitesettings={sitesettings}
              updateSettings={updateSettings}
            />
          )}
          {activeTab === "serviceworker" && (
            <ServiceWorker
              sitesettings={sitesettings}
              updateSettings={updateSettings}
            />
          )}
          {activeTab === "advanced" && (
            <Advanced
              sitesettings={sitesettings}
              updateSettings={updateSettings}
            />
          )}



          <div className={twdClass("w-full text-right mb-2")}>
            {activeTab === "manifest" && (
              <a href={`https://${domain}/manifest.webmanifest`} target='_blank'>
                <Button                
                  type={"default"} color={"white"} className={"inline-block mr-4"} 
                >
                  View <span className={twdClass("font-semibold px-2 rounded-md bg-blue-100")}>Manifest</span>
                </Button>
              </a>
            )}
             {activeTab === "serviceworker" && (
              <a href={`https://${domain}/sw.js`} target='_blank'>
                <Button                
                  type={"default"} color={"white"} className={"inline-block mr-4"} 
                >
                  View Service Worker <span className={twdClass("font-semibold px-2 rounded-md bg-blue-100")}>sw.js</span>
                </Button>
              </a>
            )}
            {activeTab === "sitemap" && (
              <a href={`${sitemapUrl}`} target='_blank'>
                <Button                
                  type={"default"} color={"white"} className={"inline-block mr-4"} 
                >
                  View sitemap                 
                </Button>
              </a>
            )}
            {activeTab === "robots" && (
              <a href={`https://${domain}/robots.txt`} target='_blank'>
                <Button                
                  type={"default"} color={"white"} className={"inline-block mr-4"} 
                >
                  View robots.txt
                </Button>
              </a>
            )}
            {activeTab === "ads" && (
              <a href={`https://${domain}/ads.txt`} target='_blank'>
                <Button                
                  type={"default"} color={"white"} className={"inline-block mr-4"} 
                >
                  View ads.txt
                </Button>
              </a>
            )}
            <Button
              type={"default"} color={"primary"} className={"inline-block"}             
              onClick={() => saveSettings()}
            >
              Save Settings
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteSettingsPanel;
