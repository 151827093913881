import {
  ADD_BLOCK_TO_PAGE,
  RIGHT_PANEL_TOGGEL,
  SELECT_BLOCK_ONCLICK,
  UPDATE_BLOCK_DATA,
  UPDATE_GENERATED,
  RESET_GENERATED,
  UPDATE_PROJECT_INFO,
  RESET_AUTOSAVE,
  RESET_PROJECT_DETAILS,
  UPDATE_PROJECT_PAGE,
  UPDATE_STYLE_ID,
  UPDATE_STYLE_PART,
  UPDATE_ADVANCED_ID,
  UPDATE_LIST_INDEX,
  UPDATE_PAGE_SETTINGS,
  CREATE_NEW_PAGE,
  UPDATED_CONNECTIONS,
  SELECT_PAGE_ID,
  UPADTE_PAGES_LIST,
  UPDATE_SITE_SETTINGS,
  UPDATE_PREVIEW_TYPE,
  RESET_PREVIEW_TYPE,
  RESET_HISTORY,
  UNDO_BLOCKS_HISTORY,
  REDO_BLOCKS_HISTORY,
  RESET_RIGHT_PANEL_LEVELS,
  ADD_RIGHT_PANEL_LEVEL,
  BACK_RIGHT_PANEL_LEVEL,
  UPDATE_RIGHT_PANEL_LEVELS,
  COPY_ELEMENT_STYLES,
} from "../constants/action.constants";
import { cloneDeep, set } from "lodash";

const initView = {
  previewType: "desktop",
};

export const studioView = (state = initView, action) => {
  switch (action.type) {
    case UPDATE_PREVIEW_TYPE:
      return { ...state, previewType: action.data };
    case RESET_PREVIEW_TYPE:
      return { ...initView };
    default:
      return state;
  }
};

const initSettings = {
  settings: {
    pageType: "static",
    pageSlug: "",
    pageName: "Home",
    paramsValues: [],
    connectionId: "",
    connectionTable: "",
    googleSheetName: "",
    slugParamColumn: "",
    metadata: {
      standard: {},
      opengraph: {},
      twitter: {},
    },
  },
};

const initSiteSettings = {
  metadata: {
    standard: {},
    opengraph: {},
    twitter: {},
  },
  externalJS: "",
  externalCSS: "",
  headTags: "",
};
const initAutoSave = {
  newActions:0,
};
const initHistory={
  page:{
    currentIndex:-1,
    items:[]
  }
}
const initCopyElementStyles={
  currentStyles:{
    blockType:false,
    blockDesign:false,
    styleElementId:false,
    properties:[]
  }
}
const initRightPanelLevels={
  currentIndex:-1,
  currentScrollTop:0,
  back:false,
  items:[]
}
const initialState = {
  projectPref:{
    autoSave:true,
  },
  autoSave:initAutoSave,
  saveTimestamp: null,
  openRightPanel: true,
  pageInfo: {
    ...cloneDeep(initSettings),
    page_id: "",
    blocks: [],
  },
  selectedBlock: null,
  styleElementId: null,
  styleElementPart: null,
  advancedElementId:null,
  selectedPageId: null,
  listIndex: null,
  sitesettings: cloneDeep(initSiteSettings),
  rightPanelLevels: cloneDeep(initRightPanelLevels),
  copyElementStyles: cloneDeep(initCopyElementStyles),
  projectData: {
    pages: [],
    connections: [],
  },
  generated:{},
  history:cloneDeep(initHistory),
};

export const studioDetails = (state = cloneDeep(initialState), action) => {

  const addToHistory = (blocks) => {
    const history = cloneDeep(state.history);
    let items=[]
    if(items.length>-1) items=history.page.items.slice(0, history.page.currentIndex+1)
    items.push(blocks)     
    history.page.currentIndex=history.page.currentIndex+1;
    history.page.items=items
    return history
  }
  
  switch (action.type) {
    case UPDATE_PROJECT_INFO:
      return { ...state, autoSave:cloneDeep(initAutoSave), projectData: action.data };
    case UPDATE_PROJECT_PAGE: {
      return { ...state, pageInfo: action.data };
    }  
    case UPDATE_GENERATED: {
      return { ...state, generated: {...state.generated, ...action.data} };
    } 
    case COPY_ELEMENT_STYLES: {
      return { ...state, copyElementStyles: {...state.copyElementStyles, currentStyles:action.data} };
    } 
    case RESET_GENERATED: {
      return { ...state, generated: {} };
    }   
    case RIGHT_PANEL_TOGGEL: {
      return { ...state, openRightPanel: action.data };
    } 
    case ADD_RIGHT_PANEL_LEVEL: {
      const rightPanelLevels = cloneDeep(state.rightPanelLevels);
      //console.log("rightPanelLevels", rightPanelLevels)
      if(rightPanelLevels.currentScrollTop){
        rightPanelLevels.currentIndex++
        rightPanelLevels.items.push({scrollTop:rightPanelLevels.currentScrollTop})
      }      
      return { ...state, rightPanelLevels};
    }    
    case BACK_RIGHT_PANEL_LEVEL: {
      const rightPanelLevels = cloneDeep(state.rightPanelLevels);
      if(rightPanelLevels.items.length) rightPanelLevels.back=true
      return { ...state, rightPanelLevels};
    }
    case UPDATE_RIGHT_PANEL_LEVELS: {      
      return { ...state, rightPanelLevels:action.data };
    }
    case RESET_RIGHT_PANEL_LEVELS: {
      return { ...state, rightPanelLevels: cloneDeep(initRightPanelLevels) };
    }   
    case ADD_BLOCK_TO_PAGE: {
      const pageInfo = cloneDeep(state.pageInfo);
      pageInfo.blocks.push(action.data);

      const history = addToHistory(pageInfo.blocks);

      return { ...state, history, pageInfo };
    }    
    case SELECT_BLOCK_ONCLICK: {
      return {
        ...state,
        selectedBlock: action.data,
        openRightPanel: true,
        styleElementId: null,
        advancedElementId: null,
        listIndex: null,
        rightPanelLevels:cloneDeep(initRightPanelLevels)
      };
    }
    case UPDATE_BLOCK_DATA: { 
      let autoSaveOrig={...state.autoSave}  
      //console.log("UPDATE_BLOCK_DATA before", autoSaveOrig)
      let autoSave={...state.autoSave, newActions:autoSaveOrig.newActions+1, fasty:autoSaveOrig.newActions+1}   
      //console.log("autoSave", autoSave)   
      //if(!state.pageInfo.orig_blocks) state.pageInfo.orig_blocks = state.pageInfo.blocks 
      if(!state.pageInfo.backupSaved) {
        console.log("BACK SAVED", state.pageInfo.backupSaved)
        state.pageInfo.orig_blocks = state.pageInfo.blocks 
        state.pageInfo.backupSaved = true 
      }

      const history = addToHistory(action.data);
      
      let ret={ ...state, autoSave, history, pageInfo: { ...state.pageInfo, blocks: action.data } }
      //let ret2=autoSave
      
      //console.log("UPDATE_BLOCK_DATA", autoSave)
      return ret;
    }
    case RESET_AUTOSAVE:
      return { ...state, autoSave:cloneDeep(initAutoSave)};
    case RESET_PROJECT_DETAILS:
      return cloneDeep(initialState);
    case UPDATE_STYLE_ID:
      return { ...state, styleElementId: action.id };
    case UPDATE_STYLE_PART:
      return { ...state, styleElementPart: action.part };
    case UPDATE_ADVANCED_ID:
      return { ...state, advancedElementId: action.id };
    case UPDATE_LIST_INDEX:
      return { ...state, listIndex: action.id };
    case UPDATE_PAGE_SETTINGS: {
      const pageInfo = cloneDeep(state.pageInfo);
      pageInfo.settings = { ...pageInfo.settings, ...action.data };
      //console.log("UPDATE_PAGE_SETTINGS", pageInfo)
      return { ...state, pageInfo };
    }
    case RESET_HISTORY:
      return { ...state, history:cloneDeep(initHistory)};
         
    case UNDO_BLOCKS_HISTORY: {
      let page={...state.history.page}
      if(page.currentIndex>-1 && page.items[page.currentIndex-1]) {
        let blocks=page.items[page.currentIndex-1]
        //console.log("undo blocks", blocks, page.items)
        return { ...state, history: {...state.history, page:{...page, currentIndex:page.currentIndex-1}}, pageInfo: { ...state.pageInfo,  blocks }  };
      }
      return {...state}
    }
    case REDO_BLOCKS_HISTORY: {
      let page={...state.history.page}
      if(page.currentIndex>-1 && (page.currentIndex+1) <= (page.items.length-1)) {
        let blocks=page.items[page.currentIndex+1]
        //console.log("redo blocks", blocks, page.items.length, page.items )
        return { ...state, history: {...state.history, page:{...page, currentIndex:page.currentIndex+1}}, pageInfo: { ...state.pageInfo,  blocks }  };
      }
      return {...state}
    }
    case UPADTE_PAGES_LIST: {
      return {
        ...state,
        projectData: {
          ...state.projectData,
          pages: action.data,
        },
      };
    }
    case SELECT_PAGE_ID: {
      return { ...state, selectedPageId: action.id };
    }
    case CREATE_NEW_PAGE: {
      const { pageName, pageSlug } = action.data;
      const intdata = {
        ...cloneDeep(initialState),
        projectData: state.projectData,
      };
      let updata = set(intdata, "pageInfo.settings.pageName", pageName);
      updata = set(intdata, "pageInfo.settings.pageSlug", pageSlug);
      //console.log("CREATE_NEW_PAGE", updata)
      return updata;
    }
    case UPDATED_CONNECTIONS: {
      return {
        ...state,
        projectData: {
          ...state.projectData,
          connections: action.data,
        },
      };
    }
    case UPDATE_SITE_SETTINGS: {
      return {
        ...state,
        sitesettings: action.data,
      };
    }
    default:
      return state;
  }
};
