import { set, get, replace } from "lodash";
import { twdClass } from "../../constants/tailwind.constants";
import { StyleBtn, SettingsBtn, CollapseBtn} from "../ui/presets";
import {
  UPDATE_STYLE_ID,
  UPDATE_STYLE_PART ,
  ADD_RIGHT_PANEL_LEVEL
} from "../../constants/action.constants";


import { useDispatch } from "react-redux";


import RenderElements from "./RenderElements";
import {hideshowElement} from "./elements/util";
import { useState } from "react";



const GG_CLS_1 = twdClass(
  "bg-gray-50 px-1 text-sm border-2 border-white hover:bg-gray-200 mb-1 rounded-xl"
);
const GG_CLS_1b = twdClass(
  "bg-white px-1 pb-2 text-sm  border-2 border-white border-gray-200 hover:border-gray-300 mb-1 rounded-xl"
);


const GE_CLS_2 = twdClass("pl-3 py-0 flex items-center cursor-pointer");
const GE_CLS_3 = twdClass("text-gray-800 font-semibold flex-grow flex items-center");
const GE_CLS_4 = twdClass("bg-white px-0 py-2");
const GE_CLS_4b = twdClass("bg-white px-0 py-2 hidden");



const GenerateGroup = ({ idx=null, element, data, updateElementValue, inputArgs}) => {  
  //console.log("GROUP", idx, element)
  const { elements, label, hasStyleProps, styleElementId, initShow=true } = element;
  const [isShow, setShow] = useState(initShow); 
  const dispatch = useDispatch(); 
  const PartEl_style = (item) => {      
      return (
        <StyleBtn  
          className={"opacity-25 group-hover:opacity-100 "}    
          onClick={() => { 
            //console.log("rec", rec)
            dispatch({ type: ADD_RIGHT_PANEL_LEVEL, data: {} })
            dispatch({
              type: UPDATE_STYLE_ID,
              id: item.styleElementId,
            });
          }}
        />
      )
  };
  const updateElementValue2 = (to, value) => {  
    //console.log(idx, to, value)
    return updateElementValue(to, value)
  }
  return (
    <div className={isShow ? GG_CLS_1b : GG_CLS_1}>
      <div className={GE_CLS_2} onClick={() => setShow(!isShow)}>
        <div className={GE_CLS_3}>
          <img width="20px" className={twdClass("mr-2")} src={'/assets/images/union.svg'}/>
          {label}
        </div>
        {hasStyleProps && (         
          <StyleBtn onClick={() => {
            dispatch({ type: ADD_RIGHT_PANEL_LEVEL, data: {} })
            dispatch({ type: UPDATE_STYLE_ID, id: styleElementId });
            dispatch({ type: UPDATE_STYLE_PART, part: null });
          }}/>
        )}
        <CollapseBtn className={'ml-1'} isOpen={isShow}/>
      </div>
      <div className={isShow ? GE_CLS_4 : GE_CLS_4b}>
        {elements.map((item, index) => { 
          let inputArgs_={...inputArgs}       
          if(item.hasStyleProps === true) inputArgs_.action = PartEl_style(item)          
          //console.log("item", idx, item)
          let item2={...item}
          if(idx!=null) item2.updateTo= replace(item2.updateTo, "[i]", `[${idx}]`);
          //console.log("Uitem", idx, item2)
          let hideElement = hideshowElement({data, element:item}) 
          if (hideElement) {
            return <></>;
          }   
          return (
            <div className={twdClass("mb-2 group")}>
              <RenderElements
                element={item2}
                data={data}
                updateElementValue={updateElementValue2}
                inputArgs={inputArgs_}
              />
            </div>
          )
        })}        
      </div>      
    </div>
  );
};

export default GenerateGroup