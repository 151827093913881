import { twdClass } from "../../constants/tailwind.constants";
import { UPDATE_BLOCK_DATA } from "../../constants/action.constants";
import { Dropdown as Dropdown2, ToolTip} from "../ui/com_ui";
import {
  COPY_ELEMENT_STYLES,
} from "../../constants/action.constants";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { find, set, get, assignIn, replace, isEmpty, cloneDeep } from "lodash";

const ElementMenu = ({ 
  sectionElem, 
  styleElementId, 
  sectionData,
  blocks,
  listIndex
}) => {
  const [isToggle, setToggle] = useState(false);

  let {
    copyElementStyles:{currentStyles={}}
  } = useSelector((state) => state.studioDetails);

  const {blockType : bT, blockDesign:bD, styleElementId: sId, properties} = currentStyles 

  const { styleElements } = sectionElem;
  const sE = find(styleElements, ["styleElementId", styleElementId])
  

  const { metaDataId, blockType, blockDesign} = sectionData;
  const dispatch = useDispatch();
  

  const copyStyles = () => {
    setToggle(true)   
    let d=[]
    sE.elements.map((el)=>{ 
      let updateToR = replace(el.updateTo, "[i]", `[${listIndex}]`)
      d.push({name:el.name, updateTo:el.updateTo, updateToR, listIndex, value:get(sectionData, updateToR)})
    })
    let styles={blockType, blockDesign, styleElementId, properties:d}
    //console.log("sectionData", sectionData)
    //console.log("sE", sE) 
    //console.log("styles", styles)

    dispatch({
      type: COPY_ELEMENT_STYLES,
      data: styles,
    });
  }

  const pasteStyles = () => {
    //console.log("Check", metaDataId, blockType, bT, properties)  
    setToggle(true)  
    if(bT != blockType) return 
    //console.log("VALID", properties)  
    let d=[]
    let blockData2={...sectionData}
    properties.map((el)=>{ 
      let updateToR = replace(el.updateTo, "[i]", `[${listIndex}]`)       
      blockData2 = set(blockData2, updateToR, el.value);      
    }) 

    let blocks2 = blocks.map((item) => {
      if (item.metaDataId === metaDataId) {
        return { ...blockData2 };
      }
      return item;
    });

    //console.log("blockData", blockData2, blocks, blocks2, properties)  
    dispatch({
      type: UPDATE_BLOCK_DATA,
      data: blocks2,
    }); 
  }

  return (
    <div className={''}> 
      {/*<Dropdown                   
        menuClassName={twdClass(" ")}
        position="left"
        buttonVariant=""
        title={<div className={twdClass("px-2 py-2 rounded-full transform hover:-translate-y-px active:translate-y-0")}><img alt="" width="24px" src="/assets/images/more-vertical.svg" />  </div>}
      >
        <div>
          <div className={twdClass(" py-1 text-md font-semibold")} style={{width:"200px"}}>                    
            <div onClick={() => {}} className={twdClass("flex items-center px-6 py-3 border-b bg-white cursor-pointer hover:bg-primary-100 active:bg-primary-200")}>
              <span className={twdClass("ml-4 flex-grow")}>Copy Styles</span>                  
            </div>
            <div onClick={() => {}} className={twdClass("flex items-center px-6 py-3 border-b bg-white cursor-pointer hover:bg-primary-100 active:bg-primary-200")}>
              <span className={twdClass("ml-4 flex-grow")}>Paste Styles</span>                  
            </div>                                                       
          </div>
        </div>            
      </Dropdown>*/}
      <Dropdown2 
        position={'left'}
        isToggle={isToggle}
        setToggle={setToggle}
        title={<div className={twdClass("ml-2 mt-1 px-1 py-1 rounded-full bg-white hover:shadow-lg active:shadow-sm")}><img alt="" width="20px" src="/assets/images/more-vertical.svg" />  </div>}
      >
        <div>
          <div className={twdClass(" py-1 text-xs font-semibold")} style={{width:"120px"}}>                    
            <div onClick={() => {copyStyles()}} className={twdClass("flex items-center px-2 py-2 border-b bg-white cursor-pointer hover:bg-primary-100 active:bg-primary-200")}>
              <span className={twdClass("ml-4 flex-grow")}>Copy Styles</span>                  
            </div>
            {(bT == blockType && bD == blockDesign && sId == styleElementId) && <div onClick={() => {pasteStyles()}} className={twdClass("flex items-center px-2 py-2 bg-white cursor-pointer hover:bg-primary-100 active:bg-primary-200")}>
              <span className={twdClass("ml-4 flex-grow")}>Paste Styles</span>                  
            </div>}                                                       
          </div>
        </div> 
      </Dropdown2>
    </div>
  );
};

export default ElementMenu;

