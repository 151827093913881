import React, { useState, useEffect } from "react";
import { twdClass } from "../../constants/tailwind.constants";
import { useDetectClickOutside } from "react-detect-click-outside";

const DropdownContent = ({ children, position='right', closeToggle }) => {
  const ref = useDetectClickOutside({ onTriggered: closeToggle });
  let c2_='left-0'
  if(position=='left'){
    c2_='right-0'
  }
  return (
    <div className={twdClass("dropdown-content relative z-10")} ref={ref}>
      <div className={twdClass(`absolute ${c2_} rounded-lg bg-white shadow-lg `)}>
        {children}
      </div>
    </div>
  );
}

const Dropdown = ({ title, position='right', children, isToggle, setToggle }) => {
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const handleToggle = (e) => {
    e.preventDefault();
    //console.log("Dropdown toggle")
    setToggleDropdown((prevState) => !prevState);
  };
  const closeToggle = () => {
    setToggleDropdown(false);    
    //console.log("close")
  };

  useEffect(()=>{
    if(isToggle) {
      setToggleDropdown(false);
      setToggle(false)
    }
  }, [isToggle])

  return (
    <div className={twdClass("relative")}>
      <button onClick={handleToggle}>{title}</button>
      {toggleDropdown && <DropdownContent position={position} closeToggle={closeToggle} >{children}</DropdownContent>}
    </div>
  );
};

export {Dropdown}
